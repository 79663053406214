import { watch } from 'vue'
import { Router } from 'vue-router'

import {
  IModulesConfig,
  IOttersSharedData,
} from '@sennder/senn-node-microfrontend-interfaces'
import { createMfWrapper, MicroFrontendLogger } from '@sennder/shell-utilities'

import {
  Middleware,
  isAuthenticated,
  projectMiddlewares,
} from './middlewares/middlewares'
import { authRoutes } from '@/modules/auth'
import {
  getModuleFmlConfig,
  moduleConfiguration,
} from '@/modules-configuration'
import { registerMiddlewares } from './middlewares/middlewares-registrator'
import { AppAnalyticsProvider, analytics } from '@/services/analyticsProvider'
import {
  getFeatureFlagForRoute,
  isModuleVisible,
} from '@/modules/visibility-handlers'
import {
  OttersExtendedData,
  getStateCallbacks,
  getStateData,
  getStateProviders,
} from '@/store'
import router from '@/router'
import { logger } from '@/services/logger/loggers'
import { isLocalEnv } from '@/common/config'
import { loggerInstance } from '@/services/logger'

const getFullData = () => {
  const data = getStateData()
  if (!data.user || !data.profile) {
    throw new Error(msg('state.data.(user | profile) is not initialized'))
  }
  if (!data.company) {
    throw new Error(msg('state.data.company is not initialized'))
  }
  return data as IOttersSharedData<OttersExtendedData>
}

const msg = (m: string) => `[µ-frontend]: ${m}`

const featureFlagWatcher = async () =>
  watch(
    () => getStateData().featureFlags,
    (featureFlags) => {
      const currentRoute = router.currentRoute.value
      if (!currentRoute) {
        return
      }
      const routeFeatureFlag = getFeatureFlagForRoute(currentRoute)
      if (routeFeatureFlag && !featureFlags[routeFeatureFlag]) {
        router.push({ path: '/' })
      }
    },
    // immediately execute to check feature flag on direct page load
    { immediate: true }
  )

const createComponent = (module: IModulesConfig) => {
  return () => {
    return createMfWrapper<IOttersSharedData>({
      allowEnvironmentOverrides: isLocalEnv(),
      getData: async () => ({
        data: getFullData(),
        callbacks: getStateCallbacks(),
        providers: getStateProviders(),
      }),
      router,
      hooks: {
        failure: logger.error,
      },
      providers: {
        getAnalytics: (ctx) => new AppAnalyticsProvider(ctx),
        getLogger: (ctx) => new MicroFrontendLogger(ctx, () => loggerInstance),
      },
      mf: {
        id: module.component,
        fml: getModuleFmlConfig(module.component),
        context: {
          analytics: module.analyticsContext,
          logger: module.logContext,
        },
      },
      watchers: [featureFlagWatcher],
    })
  }
}

const addModuleRoute = (module: IModulesConfig, router: Router) => {
  const component = createComponent(module)

  const middlewares: Middleware[] = module.middlewares
    ? module.middlewares
        .filter(
          (middlewareName) =>
            !!projectMiddlewares[
              middlewareName as keyof typeof projectMiddlewares
            ]
        )
        .map((middlewareName) => {
          return projectMiddlewares[
            middlewareName as keyof typeof projectMiddlewares
          ]
        })
    : []

  router.addRoute({
    path: module.route,
    name: module.name,
    component,
    meta: {
      layout: module.layout,
      middlewares,
      npmName: module.component,
      analyticsModuleName: module.analyticsContext.module,
    },
    children: [
      {
        path: ':catchAll(.*)',
        name: module.name,
        meta: {
          middlewares,
          npmName: module.component,
          analyticsModuleName: module.analyticsContext.module,
        },
        component,
      },
    ],
  })
}

export const registerRoutesAndMiddlewares = (router: Router) => {
  for (const route of authRoutes) {
    router.addRoute(route)
  }
  router.addRoute({
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../NotFound.vue'),
    meta: {
      middlewares: [isAuthenticated],
    },
  })

  moduleConfiguration.forEach((module: IModulesConfig) =>
    addModuleRoute(module, router)
  )

  registerMiddlewares(router)

  router.beforeResolve((to) => {
    const { name, meta } = to
    if (name) {
      analytics.trackPage(name.toString(), {
        module: meta.analyticsModuleName,
      })
    }
  })
}

export const getFirstVisibleModulePath = () => {
  const firstVisibleModule = moduleConfiguration.find((module) =>
    isModuleVisible(module, getStateData())
  )
  return firstVisibleModule?.route || '/not-found'
}

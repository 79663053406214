import {
  IModulesConfig,
  IOttersSharedData,
} from '@sennder/senn-node-microfrontend-interfaces'
import { getFederatedConfig } from './services/getFederatedConfig'
import { FederatedModule } from '@sennder/federated-module-loader'

export type OttersModuleConfig = IModulesConfig & {
  sidebarCategory?: string
  dataTest: string
}

export const moduleConfiguration: OttersModuleConfig[] = [
  {
    name: 'dashboard',
    component: 'otters-shipments',
    devPort: '9106',
    route: '/',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated'],
    sidebarIcon: 'LayoutDashboard',
    analyticsContext: {
      module: 'dashboard',
      submodule: '',
    },
    logContext: {
      module: 'dashboard',
      codeOwners: 'shipper-portal-pod',
    },
    dataTest: 'dashboard',
  },
  {
    name: 'my-shipments',
    component: 'otters-shipments',
    devPort: '9106',
    route: '/shipments',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated'],
    sidebarIcon: 'Package',
    sidebarCategory: 'my-shipments',
    analyticsContext: {
      module: 'myShipments',
      submodule: '',
    },
    logContext: {
      module: 'myShipments',
      codeOwners: 'shipper-portal-pod',
    },
    dataTest: 'my-shipments',
  },
  {
    name: 'new-shipment',
    component: 'otters-mf-component',
    devPort: '9101',
    route: '/booking',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated'],
    sidebarIcon: 'Plus',
    featureFlag: 'load-booking',
    sidebarCategory: 'my-shipments',
    analyticsContext: {
      module: 'newShipment',
      submodule: '',
    },
    logContext: {
      module: 'newShipment',
      codeOwners: 'shipper-portal-pod',
    },
    dataTest: 'new-shipment',
  },
  {
    name: 'spot-requests',
    component: 'otters-mf-component',
    devPort: '9101',
    route: '/opportunities',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated'],
    sidebarIcon: 'Package',
    featureFlag: 'opportunities',
    sidebarCategory: 'my-shipments',
    analyticsContext: {
      module: 'spotRequests',
      submodule: '',
    },
    logContext: {
      module: 'spotRequests',
      codeOwners: 'shipper-portal-pod',
    },
    dataTest: 'spot-requests',
  },
  {
    name: 'my-analytics',
    component: 'otters-mf-component',
    devPort: '9101',
    route: '/analytics',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated'],
    sidebarIcon: 'ChartNoAxesColumn',
    featureFlag: 'analytics',
    analyticsContext: {
      module: 'myAnalytics',
      submodule: '',
    },
    logContext: {
      module: 'myAnalytics',
      codeOwners: 'shipper-portal-pod',
    },
    dataTest: 'my-analytics',
  },
  {
    name: 'my-analytics-2',
    component: 'myanalytics-mf',
    devPort: '9105',
    route: '/my-analytics',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated'],
    sidebarIcon: 'ChartNoAxesColumn',
    featureFlag: 'my-analytics',
    analyticsContext: {
      module: 'myanalytics-mf',
      submodule: '',
    },
    logContext: {
      module: 'myanalytics-mf',
      codeOwners: 'shipper-portal-pod',
    },
    dataTest: 'my-analytics-2',
  },
  {
    name: 'new-shipment-success',
    component: 'otters-mf-component',
    devPort: '9101',
    route: '/success',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated'],
    sidebarIcon: '',
    analyticsContext: {
      module: 'newShipment',
      submodule: '',
    },
    logContext: {
      module: 'newShipment',
      codeOwners: 'shipper-portal-pod',
    },
    dataTest: 'new-shipment-success',
  },
]

/* Temporary helpers until app is migrated to new config */

export const moduleFmlConfigMap = new Map<
  string,
  FederatedModule<IOttersSharedData>
>(
  moduleConfiguration.map((config) => [
    config.component,
    getFederatedConfig(config),
  ])
)

export const getModuleFmlConfig = (component: string) => {
  const config = moduleFmlConfigMap.get(component)
  if (!config) {
    throw new Error(`FML config not found for module ${component}`)
  }
  return config
}

import { getStateData, loadState } from '@/store'
import { getAuth0Header } from '@/services/tokenManager'
import { identifyUserInAnalytics } from '@/services/analytics'
import { translationProvider } from '@/services/translationProvider'
import { identifyUserInTracking } from '@/services/tracking'

type UserData = {
  onLogin: boolean
}

export const loadUserData = async ({ onLogin = false }: UserData) => {
  const authHeader = await getAuth0Header()
  if (!authHeader) {
    console.warn('[Otters shell - userDataHelper] - Undefined token')
    return
  }

  if (!(await loadState())) {
    return
  }

  identifyUserInAnalytics({ onLogin })

  await translationProvider.set(getStateData().language)
  identifyUserInTracking()
}
